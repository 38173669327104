import React, {FunctionComponent, useState, useEffect} from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {GutterMaxWidth, Gutters} from '../components/Spacing/Gutters';
import {CardStyle} from '../components/Card';
import {Theme} from '../theme/Theme';
import {Button} from '../components/Button';
import {MediaCard, MediaType} from '../components/MediaCard';
import {graphql, useStaticQuery, PageProps} from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import {DownloadIcon} from '../components/Icon/DownloadIcon';
import {DownloadButton} from '../components/DownloadButton';
import {detect, DeviceOS} from '../utils/detect';
import {CollectiveSupportedDevices, pathFor} from '../constants/paths';
import {Dictionary} from '../types/Dictionary';
import {getPlanDescription} from '../lib/plans';
import getQueryParameter from '../utils/get-query-parameters';
import {getUserFromLocalStorage} from '../utils/user-info';
import {OAuthOptions, refreshNewlySubscribedUser} from '../utils/auth';
import {FooterContent} from '../types/FooterContent';
import {GettingStartedPageQuery} from './__generated__/GettingStartedPageQuery';
import {FluidObject} from 'gatsby-image';
import {createApi} from '../lib/api';
import {
  PaymentsApi,
  PlansListPlansInner,
  UserApi,
} from '@focusrite-novation/ampify-api';
import {sendAnalyticsEvent} from '../utils/countly';
import {PurchaseGreeting} from '../components/PurchaseGreeting/PurchaseGreeting';
import {SubscribeGreeting} from '../components/SubscribeGreeting/SubscribeGreeting';

import {ButtonVariant} from '../components/Button/ButtonVariant';

import {getProductForItemCode} from '../lib/purchases';
import {
  ContentfulProduct,
  GettingStartedStep,
} from '../types/ContentfulProductContent';
import {DownloadModal} from '../components/DownloadModal/DownloadModal';
import {ProductV2} from '../types/ProductV2';

const colours = [
  Theme.colours.yellow,
  Theme.colours.orange,
  Theme.colours.blue,
  Theme.colours.red,
];

interface GettingStartedProps extends PageProps {
  pageContext: {
    download: {fastEqualiser: Dictionary<string>};
    contentfulProductContent: ContentfulProduct | {node: ProductV2};
    contentfulFooterContent: FooterContent;
    customerPortalBaseUrl: string;
  };
  site: {
    siteMetdadata: {
      ampifyApi: {
        baseUrl: string;
      };
      releases: {
        baseUrl: string;
      };
    };
  };
}

const renderButton = (
  step: GettingStartedStep,
  index: number,
  downloadLinks: Dictionary[string],
  tabIndex: number,
  slug: string,
  softwareId: string
) => {
  if (step) {
    const backgroundColour = step.colour ? step.colour : colours[index];
    const textColour =
      backgroundColour === Theme.colours.yellow
        ? Theme.colours.black
        : Theme.colours.white;

    return step.isDownloadButton ? (
      <DownloadButton
        tabIndex={tabIndex}
        os={detect.os()}
        onDownload={(os: DeviceOS, link: string, productSoftwareId: string) => {
          sendAnalyticsEvent(`download-product`, {
            product: productSoftwareId,
            page: 'getting-started',
            os,
          });

          window.location.assign(link);
        }}
        links={downloadLinks}
        style={{marginTop: `${Theme.space[4]}px`}}
        testID="download-button"
        buttonText="Download now"
        softwareId={slug}
      />
    ) : (
      <Button
        tabIndex={tabIndex}
        background={backgroundColour}
        color={textColour}
        style={{marginTop: `${Theme.space[4]}px`}}
      >
        {step.title}
        <DownloadIcon
          style={{marginLeft: `${Theme.space[4]}px`}}
          colour={textColour}
        />
      </Button>
    );
  }
};

const renderBundleButton = (
  step: GettingStartedStep,
  handleBundleDownload: (os: CollectiveSupportedDevices) => void,
  tabIndex: number,
  softwareId: string,
  variant?: ButtonVariant
) => {
  return (
    step.isDownloadButton && (
      <DownloadButton
        softwareId={softwareId}
        tabIndex={tabIndex}
        os={detect.os()}
        onDownload={(os: DeviceOS) => {}}
        onDownloadBundle={handleBundleDownload}
        style={{marginTop: `${Theme.space[4]}px`}}
        testID="download-button"
        buttonText="Download now"
        buttonVariant={variant}
        alternativeBackgroundColour={
          variant !== ButtonVariant.YELLOW ? Theme.colours.black : undefined
        }
      />
    )
  );
};

const GettingStarted: FunctionComponent<GettingStartedProps> = (props) => {
  const data: GettingStartedPageQuery = useStaticQuery(graphql`
    query GettingStartedPageQuery {
      lifestyle: file(relativePath: {eq: "fast-equaliser-lifestyle.png"}) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      site {
        siteMetadata {
          ampifyApi {
            baseUrl
          }
          releases {
            baseUrl
          }
          auth {
            client_id
            scope
          }
        }
      }
    }
  `);

  const {
    slug,
    softwareId,
    gettingStartedButtonText,
    gettingStartedSteps,
    gettingStartedTitle,
    mobileDownloadMessage,
  } = props.pageContext.contentfulProductContent.node;

  const [plans, setPlans] = useState<PlansListPlansInner[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [planId, setPlanId] = useState('');
  const [itemId, setItemId] = useState('');
  const [planDescription, setPlanDescription] = useState('');
  const [userName, setUserName] = useState('');
  const [buttonVariant, setButtonVariant] = useState<ButtonVariant>(
    ButtonVariant.YELLOW
  );
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [bundleDownloadOs, setBundleDownloadOs] =
    useState<CollectiveSupportedDevices>(
      detect.os() as CollectiveSupportedDevices
    );
  const siteMetadata = data?.site?.siteMetadata;

  const getAuthorisationOpts = (): OAuthOptions => {
    const auth = siteMetadata?.auth;
    if (!auth || !auth.client_id || !auth.scope) {
      throw new Error('No oauth settings configured');
    }
    return {
      client_id: auth.client_id,
      scope: auth.scope,
    };
  };

  const getUser = () => {
    const user = getUserFromLocalStorage();
    if (!user) {
      throw new Error('User not found');
    }
    if (typeof user.firstName !== 'string') {
      throw new Error(`User ${user.id} has no first name`);
    }
    return user;
  };

  const removeLocalCouponCode = () => {
    if (window?.localStorage) {
      window.localStorage.removeItem('coupon_code');
    }
  };

  const handleSubscriber = async (
    queryPlanId: string,
    userApi: UserApi,
    paymentsApi: PaymentsApi
  ) => {
    setPlanId(queryPlanId);
    try {
      await refreshNewlySubscribedUser(userApi, queryPlanId, 15);
      const user = getUser();
      const planDescription = await getPlanDescription(
        paymentsApi,
        queryPlanId
      );

      setUserName(user?.firstName as string);
      setPlanDescription(planDescription);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePurchaser = async (
    queryItemId: string,
    paymentsApi: PaymentsApi
  ) => {
    setItemId(queryItemId);

    try {
      const res = await paymentsApi.getUserPurchases();
      const purchases = res.data.purchases;
      if (!purchases?.find((purchase) => purchase.id === queryItemId)) {
        return;
      }
      const user = getUser();
      const product = await getProductForItemCode(paymentsApi, queryItemId);

      setUserName(user?.firstName as string);
      setPlanDescription(product?.name!);
    } catch (error) {}
  };

  useEffect(() => {
    (async () => {
      const queryPlanId = getQueryParameter(window, 'planId');
      const queryItemId = getQueryParameter(window, 'itemId');
      const baseUrl = siteMetadata?.ampifyApi?.baseUrl as string;
      const userApi = createApi(UserApi, baseUrl, getAuthorisationOpts());
      const paymentsApi = createApi(
        PaymentsApi,
        baseUrl,
        getAuthorisationOpts()
      );

      const plans = (await paymentsApi.getPlans()).data;
      setPlans(plans.plans!);

      if (queryPlanId) {
        await handleSubscriber(queryPlanId, userApi, paymentsApi);
      } else if (queryItemId) {
        await handlePurchaser(queryItemId, paymentsApi);
      }

      removeLocalCouponCode();

      setIsLoading(false);
    })();
  }, []);

  const handleBundleDownload = async (os: CollectiveSupportedDevices) => {
    setBundleDownloadOs(os);
    setShowDownloadModal(true);
  };

  return (
    <Layout
      {...props.pageContext.contentfulFooterContent}
      customerPortalBaseUrl={props.pageContext.customerPortalBaseUrl}
    >
      <SEO title="Getting Started" />
      {planId ? (
        <SubscribeGreeting
          isLoading={isLoading}
          userName={userName}
          planDescription={planDescription}
        />
      ) : itemId ? (
        <PurchaseGreeting
          isLoading={isLoading}
          userName={userName}
          planDescription={planDescription}
        />
      ) : (
        <Gutters paddingTop />
      )}
      <Gutters maxWidth={GutterMaxWidth.LARGE} paddingSides>
        <h3 data-testid="getting-started-title">{gettingStartedTitle}</h3>
      </Gutters>
      <Gutters
        maxWidth={GutterMaxWidth.LARGE}
        data-testid="getting-started-container"
      >
        {showDownloadModal && (
          <DownloadModal
            page="getting-started"
            plans={plans}
            baseUrl={siteMetadata?.releases?.baseUrl!}
            handleClose={() => setShowDownloadModal(false)}
            softwareId={softwareId}
            os={bundleDownloadOs}
          />
        )}

        {gettingStartedSteps.map((step: any, stepIndex: number) => (
          <MediaCard
            tabIndex={0}
            key={`step-${stepIndex}`}
            testID={`step-${stepIndex}`}
            media={
              step.videoId
                ? {
                    type: MediaType.VIDEO,
                    videoID: step.videoId,
                    playerID: `step-${stepIndex}`,
                  }
                : {
                    type: MediaType.IMAGE,
                    render: () => (
                      <BackgroundImage
                        style={{
                          width: '100%',
                          boxSizing: `inherit`,
                          minHeight: '400px',
                        }}
                        fluid={
                          data?.lifestyle?.childImageSharp?.fluid as FluidObject
                        }
                      />
                    ),
                  }
            }
            cardStyle={CardStyle.BORDER}
            colour={
              step.isDownloadButton
                ? Theme.colours.grey
                : step.colour
                ? step.colour
                : colours[stepIndex]
            }
            flexDirection={stepIndex % 2 !== 0 ? 'row' : 'row-reverse'}
          >
            <h3>{step.title}</h3>
            <span>
              {!detect.isCollectiveSupportedOS() && mobileDownloadMessage
                ? mobileDownloadMessage
                : step.body.body}
            </span>
            {!slug.startsWith('fast-bundle') && step.title == 'Download'
              ? renderButton(
                  step,
                  stepIndex,
                  pathFor(siteMetadata?.releases?.baseUrl || '', softwareId),
                  0,
                  slug,
                  softwareId
                )
              : renderBundleButton(
                  step,
                  handleBundleDownload,
                  0,
                  softwareId,
                  buttonVariant
                )}
          </MediaCard>
        ))}
      </Gutters>
    </Layout>
  );
};

export default GettingStarted;

import React, {FunctionComponent} from 'react';
import Theme from '../../theme';
import {Card, CardStyle} from '../Card';
import {Group} from '../Group';
import {GutterMaxWidth, Gutters} from '../Spacing/Gutters';
import {
  GreetingProps,
  Loading,
  PersonalisedProps,
} from '../SubscribeGreeting/SubscribeGreeting';

export const PurchaseGreeting: FunctionComponent<GreetingProps> = ({
  isLoading,
  userName,
  planDescription,
}) => (
  <Gutters
    data-testid="subscription-thank-you"
    padding
    maxWidth={GutterMaxWidth.SMALL}
    paddingSides
  >
    {isLoading ? (
      <Loading />
    ) : (
      <Card cardStyle={CardStyle.THICK_TOP} colour={Theme.colours.black}>
        <Group>
          {planDescription && userName ? (
            <PurchasePersonalised
              planDescription={planDescription}
              userName={userName}
            />
          ) : (
            <NonPersonalisedPurchase />
          )}
        </Group>
      </Card>
    )}
  </Gutters>
);

export const PurchasePersonalised: FunctionComponent<PersonalisedProps> = ({
  planDescription,
  userName,
}) => (
  <>
    <h3>
      Thank you for purchasing {planDescription}, {userName}.
    </h3>
    <span>
      Our team have put together some useful content to help you get started
      with {planDescription}.
    </span>
  </>
);

export const NonPersonalisedPurchase: FunctionComponent = () => (
  <>
    <h3>Thank you for your purchase.</h3>
    <span>
      Purchase details have been sent to you via email. Our team have put
      together some useful content to help you get started.
    </span>
  </>
);

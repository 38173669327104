import {
  writeTokenToLocalStorage,
  writeUserToLocalStorage,
  isSubscribedToPlan,
} from './user-info';
import {UserApi, User} from '@focusrite-novation/ampify-api';
import {getHostname} from './login-url';

export interface OAuthOptions {
  client_id: string;
  scope: string;
}

const sleepForASecond = async () =>
  new Promise((resolve) => setTimeout(resolve, 1000));

export async function refreshNewlySubscribedUser(
  userApi: UserApi,
  plan: string,
  attempts: number
) {
  if (attempts === 0) {
    throw new Error('User does not have plan in account');
  }
  await refreshUser(userApi);
  if (!isSubscribedToPlan(plan)) {
    attempts -= 1;
    await sleepForASecond();
    await refreshNewlySubscribedUser(userApi, plan, attempts);
  }
}

export async function getUserWithAuthCode(
  userApi: UserApi,
  authCode: string,
  oauthOptions: OAuthOptions
): Promise<User> {
  const tokenResponse = await userApi.getAuthToken(
    'authorization_code',
    undefined,
    undefined,
    oauthOptions.client_id,
    oauthOptions.scope,
    undefined,
    authCode,
    `https://${getHostname()}/oauth`
  );

  writeTokenToLocalStorage(tokenResponse.data);
  return await refreshUser(userApi);
}

export async function refreshUser(userApi: UserApi) {
  const user: User = (await userApi.getUser()).data as User;
  writeUserToLocalStorage(user);
  return user;
}

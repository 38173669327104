import React, {FunctionComponent} from 'react';
import {IconProps} from './IconTypes';
import {Theme} from '../../theme/Theme';
import styled from 'styled-components';

interface StyledSVGProps {
  animate?: boolean;
}

const StyledSVG = styled.svg<StyledSVGProps>`
  polygon,
  path {
    transition: transform 360ms ease-out;
  }

  :hover {
     {
      polygon {
        transform-origin: center;
        transform: translateY(60px);
      }
      path {
        transform-origin: center;
        transform: translateY(-20px);
      }
    }
  }
`;

interface DownloadIconProps extends IconProps, StyledSVGProps {}

export const DownloadIcon: FunctionComponent<DownloadIconProps> = ({
  colour = Theme.colours.white,
  width = '19px',
  height = '26px',
  style,
}) => (
  <StyledSVG width={width} height={height} viewBox="0 0 381 470" style={style}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon
        fill={colour}
        fillRule="nonzero"
        points="74 103.7 138.9 103.7 138.9 0.6 242.8 0.6 242.8 103.7 307.7 103.7 190.8 251"
      />
      <path
        d="M356,322.2 C342.2,322.2 331,333.4 331,347.2 L331,419.3 L50.7,419.3 L50.7,347.2 C50.7,333.4 39.5,322.2 25.7,322.2 C11.9,322.2 0.7,333.4 0.7,347.2 L0.7,444.3 C0.7,458.1 11.9,469.3 25.7,469.3 L356,469.3 C369.8,469.3 381,458.1 381,444.3 L381,347.2 C381,333.4 369.8,322.2 356,322.2 Z"
        id="Path"
        fill={colour}
        fillRule="nonzero"
      />
    </g>
  </StyledSVG>
);
